import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from 'src/app/shared/ui/modal-dialog/modal-dialog.component';

export interface DialogData {
  isError?: boolean;
  title: string;
  subtitle?: string;
  customSubtitleValue?: string;
  content: string;
  ctaLabel: string;
}

@Injectable({ providedIn: 'root' })
export class UiService {
  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}

  showErrorDialog(args: {
    subtitle?: string;
    customSubtitleValue?: string;
    content: string;
    disableClose?: boolean;
  }): MatDialogRef<ModalDialogComponent, DialogData> {
    const { subtitle, customSubtitleValue, content, disableClose } = args;
    return this.dialog.open(ModalDialogComponent, {
      data: {
        isError: true,
        title: 'i18n.common.error',
        subtitle,
        customSubtitleValue,
        content,
        ctaLabel: 'i18n.common.logout'
      },
      disableClose
    });
  }

  showInfoDialog(data: DialogData): MatDialogRef<ModalDialogComponent, DialogData> {
    return this.dialog.open(ModalDialogComponent, {
      data: { isError: false, ...data }
    });
  }

  showErrorBar(message: string, durationInMs?: number): void {
    const closeText = this.translate.instant('i18n.common.close');
    this.snackbar.open(message, closeText, {
      panelClass: 'balluff-error-bar',
      duration: durationInMs
    });
  }

  showInfoBar(message: string, durationInMs?: number): void {
    const closeText = this.translate.instant('i18n.common.close');
    this.snackbar.open(message, closeText, { duration: durationInMs });
  }
}
