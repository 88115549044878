import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'pms-demo',
  template: ''
})
export class DemoLoginRedirectPage implements OnInit {
  constructor(
    private msal: MsalService,
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const loginHint =
      this.route.snapshot.queryParamMap.get('login_hint') ?? 'portable-monitoring@balluff.de';

    this.msal.loginRedirect({
      ...this.msalGuardConfig.authRequest,
      loginHint,
      redirectStartPage: '/welcome'
    } as RedirectRequest);
  }
}
