import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { BrowserUtils, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { AppFacade } from 'src/app/app.facade';
import { DestroyableComponent } from './shared/ui/destroyable/destroyable.component';

@Component({
  selector: 'pms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroyableComponent implements OnInit, OnDestroy {
  isIframe = BrowserUtils.isInIframe();

  constructor(
    private readonly appFacade: AppFacade,
    private readonly msalBroadcast: MsalBroadcastService,
    public router: Router
  ) {
    super();
    this.appFacade.initializeTranslations();
  }

  ngOnInit(): void {
    this.appFacade.initializeIcons();
    this.appFacade.handleChangingUserPermissions();

    this.msalBroadcast.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.appFacade.checkAndSetActiveAccount();
      });

    this.appFacade.connectToSignalRHub();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.appFacade.disconnectFromSignalRHub();
  }
}
