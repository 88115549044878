import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { Adb2cClaims } from './constants';
import { CustomerInfo } from './shared/data-access/models/customer-info.model';
import { Role } from './shared/data-access/models/role.model';

@Injectable({
  providedIn: 'root'
})
export class AppState {
  private accountInfoSubject = new BehaviorSubject<AccountInfo | undefined | null>(undefined);
  readonly accountInfo$ = this.accountInfoSubject.asObservable();

  private customerInfoSubject = new BehaviorSubject<CustomerInfo | undefined | null>(undefined);
  readonly customerInfo$ = this.customerInfoSubject.asObservable();

  readonly isAdmin$ = this.accountInfo$.pipe(
    pluck('idTokenClaims', Adb2cClaims.ROLE),
    map((role: Role) => role === 'Admin')
  );

  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  readonly isLoggedIn$ = this.isLoggedInSubject.asObservable();

  setAccountInfo(accountInfo: AccountInfo): void {
    this.accountInfoSubject.next(accountInfo);
  }

  clearAccountInfo(): void {
    this.accountInfoSubject.next(null);
  }

  setCustomerInfo(customerInfo: CustomerInfo): void {
    this.customerInfoSubject.next(customerInfo);
  }

  clearCustomerInfo(): void {
    this.customerInfoSubject.next(null);
  }

  setIsLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedInSubject.next(isLoggedIn);
  }
}
