<mat-toolbar
  class="mat-typography"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="1.5em"
  *ngIf="currentLanguage$ | async as currentLanguage"
>
  <img class="balluff-logo" src="../../../../assets/images/logos/BalluffLogoColor.svg" alt="Logo" />

  <h1 fxFlex>Portable Monitoring System</h1>

  <div fxFlex></div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch">
    <button class="help-btn" mat-icon-button [routerLink]="['/help']">
      <mat-icon class="help-icn">help_outline</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="languageMenu">
      <img
        *ngIf="currentLanguage"
        src="assets/flags/{{ getLanguage(currentLanguage)?.flag }}.png"
        class="language-flag"
        [alt]="'Flag of ' + getLanguage(currentLanguage)?.flag"
      />
    </button>

    <mat-menu #languageMenu="matMenu" xPosition="before" yPosition="below">
      <button
        mat-menu-item
        *ngFor="let language of languages"
        (click)="changeLanguage(language.code)"
      >
        <img
          src="assets/flags/{{ language.flag }}.png"
          class="menu language-flag"
          [alt]="language.name"
        />
        <span> {{ language.name | translate }} </span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
