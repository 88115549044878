<div class="container" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
  <balluff-help-page [helpConfig]="helpConfig"></balluff-help-page>

  <section fxFill fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <a
      class="primary mat-elevation-z8"
      mat-flat-button
      href="https://assets.balluff.com/WebBinary1/MAN_BAI_BPM_S12S_S2N_DE_EN_H21_DOK_951352_01_000.pdf"
      target="_blank"
      >{{ 'i18n.helpPage.softwareManual' | translate }}</a
    >
    <a
      class="primary mat-elevation-z8"
      mat-flat-button
      href="https://assets.balluff.com/WebBinary1/MAN_BNI_EGW_566_002_K082_DE_EN_F21_DOK_951206_01_000.pdf"
      target="_blank"
      >{{ 'i18n.helpPage.hardwareManual' | translate }}</a
    >
  </section>
</div>
