import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { BreakpointService } from '@balluff/ngx-standard/core';
import { AppState } from 'src/app/app.state';
import { environment } from 'src/environments/environment';
import { AppFacade } from '../../app.facade';

@Injectable({ providedIn: 'root' })
export class HeaderFacade {
  readonly isAdmin$ = this.appState.isAdmin$;
  readonly isLoggedIn$ = this.appState.isLoggedIn$;
  readonly isMobile$ = this.breakpointService.mobile$;

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private appFacade: AppFacade,
    private appState: AppState,
    private breakpointService: BreakpointService,
    private msal: MsalService
  ) {}

  logout(): void {
    this.appFacade.logout();
  }

  resetPassword(): void {
    const resetPasswordFlowRequest: RedirectRequest = {
      scopes: environment.adb2c.scopes,
      authority: environment.adb2c.authorities.passwordReset.authority
    };

    if (this.msalGuardConfig.authRequest) {
      this.msal.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...resetPasswordFlowRequest
      } as RedirectRequest);
    } else {
      this.msal.loginRedirect(resetPasswordFlowRequest);
    }
  }

  editProfile(): void {
    const editProfileFlowRequest = {
      scopes: environment.adb2c.scopes,
      authority: environment.adb2c.authorities.editProfile.authority
    };

    if (this.msalGuardConfig.authRequest) {
      this.msal.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...editProfileFlowRequest
      } as RedirectRequest);
    } else {
      this.msal.loginRedirect(editProfileFlowRequest);
    }
  }
}
