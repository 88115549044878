export const environment = {
  production: false,
  debug: true,
  env: 'dev',
  api: {
    uri: 'https://pmsys-backend-dev.azurewebsites.net/api'
  },
  adb2c: {
    clientId: '5d460340-2df0-4e1c-80ed-bb452620f735',
    authorities: {
      editProfile: {
        authority:
          'https://portmonsysdevb2c.b2clogin.com/portmonsysdevb2c.onmicrosoft.com/B2C_1_Edit_Profile'
      },
      passwordReset: {
        authority:
          'https://portmonsysdevb2c.b2clogin.com/portmonsysdevb2c.onmicrosoft.com/B2C_1_PasswordReset'
      },
      signUpSignIn: {
        authority:
          'https://portmonsysdevb2c.b2clogin.com/portmonsysdevb2c.onmicrosoft.com/B2C_1_Signup'
      }
    },
    authorityDomain: 'portmonsysdevb2c.b2clogin.com',
    scopes: [
      'openid',
      'https://portmonsysdevb2c.onmicrosoft.com/c878f897-9c92-4ee1-abae-53311382f537/Backend'
    ]
  }
};
