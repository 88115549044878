import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { AppState } from '../../../app.state';
import { CustomerApiService } from '../../../register-customer/data-access/customer-api/customer-api.service';
import { UiService } from '../../utils/ui/ui.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerIdGuard implements CanActivate {
  constructor(
    private appState: AppState,
    private customerApi: CustomerApiService,
    private router: Router,
    private ui: UiService
  ) {}

  canActivate(): Observable<UrlTree | boolean> {
    return this.customerApi.getCustomerInfo().pipe(
      take(1),
      tap(customerInfo => {
        this.appState.setCustomerInfo(customerInfo);
      }),
      map(customerInfo => {
        return customerInfo.id ? true : this.router.parseUrl('/customer/register');
      }),
      catchError(err => {
        this.appState.clearCustomerInfo();

        if (err instanceof HttpErrorResponse) {
          if (err.status === 404) {
            return of(this.router.parseUrl('/customer/register'));
          } else {
            this.ui.showErrorBar(err.message);
          }
        } else {
          console.error(err);
        }
        return of(false);
      })
    );
  }
}
