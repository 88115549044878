import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeFooterComponent } from './home-footer.component';

@NgModule({
  imports: [SharedModule, MatToolbarModule, MatIconModule],
  exports: [HomeFooterComponent],
  declarations: [HomeFooterComponent]
})
export class HomeFooterModule {}
