import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SignalRConnectionInfo } from './models/signalr-connection-info.model';
import {
  ConnectionSatusChangedEvent,
  RuleStatusChangedEvent,
  SignalREvents,
  TelemetryReceivedEvent
} from './models/signalr-events.model';
import { SignalRTarget } from './models/signalr-targets.model';

@Injectable({ providedIn: 'root' })
export class SignalRService {
  private connection: HubConnection;

  private deviceConnectionStatusSubject = new Subject<ConnectionSatusChangedEvent>();
  private telemetryEventSubject = new Subject<TelemetryReceivedEvent>();
  private ruleSubject = new Subject<RuleStatusChangedEvent>();

  deviceConnectionStatusChanged$ = this.deviceConnectionStatusSubject.asObservable();
  telemetryEvent$ = this.telemetryEventSubject.asObservable();
  ruleStatusChanged$ = this.ruleSubject.asObservable();

  constructor(private http: HttpClient) {}

  connectToHub(userId: string): void {
    if (!this.connection || this.connection?.state === HubConnectionState.Disconnected) {
      this.getConnectionInfo(userId).subscribe(connectionInfo => {
        this.connection = this.getHubConnection(connectionInfo);
        this.connection
          .start()
          .then(
            () => {
              console.log('SignalR connection started');
              this.setupEventListeners();
            },
            reason => console.log(reason)
          )
          .catch(error => console.error(error));
      });
    } else {
      console.log(`SignalR Hub is already connected with ID: ${this.connection.connectionId}`);
    }
  }

  private getConnectionInfo(userId: string): Observable<SignalRConnectionInfo> {
    const requestUrl = `${environment.api.uri}/negotiate/${userId}`;
    return this.http.get<SignalRConnectionInfo>(requestUrl);
  }

  private getHubConnection(connectionInfo: SignalRConnectionInfo): HubConnection {
    const options = {
      accessTokenFactory: () => connectionInfo.accessToken
    };

    return new HubConnectionBuilder()
      .withUrl(connectionInfo.url, options)
      .withAutomaticReconnect()
      .configureLogging(environment.debug ? LogLevel.Information : LogLevel.Error)
      .build();
  }

  private setupEventListeners(): void {
    this.connection.on(
      SignalRTarget.DeviceConnectionStatus,
      (event: ConnectionSatusChangedEvent) => {
        this.debugLog('ConnectionSatusEvent', event);
        this.deviceConnectionStatusSubject.next(event);
      }
    );

    this.connection.on(SignalRTarget.Telemetry, (event: TelemetryReceivedEvent) => {
      this.debugLog('TelemetryEvent', event);
      this.telemetryEventSubject.next(event);
    });

    this.connection.on(SignalRTarget.Rules, (event: RuleStatusChangedEvent) => {
      this.debugLog('RulesEvent', event);
      this.ruleSubject.next(event);
    });

    this.connection.onclose(() => console.log('SignalR disconnected'));
  }

  private debugLog(message: string, event: SignalREvents): void {
    if (environment.debug) {
      console.log(message, event);
    }
  }

  async disconnectFromHub(): Promise<void> {
    this.connection.off(SignalRTarget.DeviceConnectionStatus);
    this.connection.off(SignalRTarget.Rules);
    this.connection.off(SignalRTarget.Telemetry);
    await this.connection.stop();
    console.log('SignalR connection stopped');
  }
}
