import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BalluffHelpModule } from '@balluff/ngx-standard/help';
import { SharedModule } from 'src/app/shared/shared.module';

import { HelpPage } from './help.page';

@NgModule({
  declarations: [HelpPage],
  imports: [SharedModule, BalluffHelpModule, MatButtonModule],
  exports: []
})
export class HelpPageModule {}
