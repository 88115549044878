import { Injectable } from '@angular/core';
import { TimeRangeSelection } from '../../gateway-detail/ui/time-range-selector/time-range-selector.component';

@Injectable({ providedIn: 'root' })
export class LocalPersistenceService {
  private persist<T>(args: { key: string; obj: T }): void {
    const stringifiedObj = typeof args.obj !== 'string' ? JSON.stringify(args.obj) : args.obj;
    try {
      localStorage[args.key] = stringifiedObj;
    } catch (error) {
      console.error(error);
    }
  }

  private load<T>(key: string): T | string | null {
    const stringifiedStoredObj = localStorage.getItem(key);
    try {
      const storedObj = JSON.parse(stringifiedStoredObj) as T;
      return storedObj;
    } catch (error) {
      return error.message.includes('SyntaxError') ? stringifiedStoredObj : null;
    }
  }

  saveTimeRangeSelection(deviceId: string, selection: TimeRangeSelection): void {
    this.persist({ key: `chart-timespan-new-${deviceId}`, obj: selection });
  }

  loadTimeRangeSelection(deviceId: string): TimeRangeSelection | null {
    return this.load<TimeRangeSelection>(`chart-timespan-new-${deviceId}`) as TimeRangeSelection;
  }

  saveForceLogout(forceLogout: boolean): void {
    forceLogout
      ? this.persist({ key: 'forceLogout', obj: forceLogout })
      : localStorage.removeItem('forceLogout');
  }

  loadForceLogout(): boolean | null {
    return this.load<boolean>('forceLogout') as boolean;
  }

  saveLogoutOn403(logout: boolean): void {
    logout ? this.persist({ key: 'logout403', obj: logout }) : localStorage.removeItem('logout403');
  }

  loadLogoutOn403(): boolean | null {
    return this.load<boolean>('logout403') as boolean;
  }

  saveBalluffBrowserLang(languageCode: string): void {
    this.persist({ key: 'balluff.browserLang', obj: languageCode });
  }

  loadBalluffBrowserLang(): string | null {
    return this.load('balluff.browserLang');
  }
}
