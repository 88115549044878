import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { BalluffAppHeaderModule } from '@balluff/ngx-standard/app-header';
import { SharedModule } from 'src/app/shared/shared.module';

import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [SharedModule, RouterModule, BalluffAppHeaderModule, MatIconModule, MatMenuModule],
  exports: [HeaderComponent]
})
export class HeaderModule {}
