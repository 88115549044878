<balluff-app-header class="header">
  <balluff-header-label>
    <img class="title-logo" src="assets/images/logos/BalluffLogo.svg" [routerLink]="['/']" />
    <span class="title">{{ "i18n.common.appName" | translate }}</span>
  </balluff-header-label>

  <balluff-header-actions class="langSelector" *ngIf="(isLoggedIn$ | async) === false">
    <balluff-language-switcher *ngIf="(isMobile$ | async) === false" iconPath="assets/flags/">
    </balluff-language-switcher>
  </balluff-header-actions>

  <balluff-header-actions *ngIf="isLoggedIn$ | async">
    <balluff-user-menu (userLogout)="onLogout()">
      <balluff-language-switcher *ngIf="(isMobile$ | async) === false" iconPath="assets/flags/">
      </balluff-language-switcher>
      <button mat-menu-item (click)="onEditProfile()">
        <mat-icon>account_circle</mat-icon>
        {{ 'i18n.appHeader.menu.editProfile' | translate }}
      </button>
      <button mat-menu-item (click)="onChangePassword()">
        <mat-icon>lock</mat-icon> {{ 'i18n.appHeader.menu.changePassword' | translate }}
      </button>
      <button mat-menu-item *ngIf="isAdmin$ | async" routerLink="/settings">
        <mat-icon>settings</mat-icon>
        {{ 'i18n.appHeader.menu.settings' | translate }}
      </button>
      <button mat-menu-item [routerLink]="'/help'">
        <mat-icon>help</mat-icon> {{ 'i18n.common.help' | translate }}
      </button>
    </balluff-user-menu>
  </balluff-header-actions>
</balluff-app-header>
