<div class="container" fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxFlex fxFlexAlign="start" fxLayout="column" fxLayoutAlign="start end">
    <section>
      <!-- Not translated by intent -->
      <h2>Remote Monitoring</h2>
      <!-- Not translated by intent -->
      <p class="slogan">Anywhere. Anytime. Anything.</p>
    </section>
  </div>

  <div fxLayout="column" fxFlex="0 1 auto" fxLayoutAlign="center center">
    <button mat-raised-button (click)="loginButtonPressed.emit()">
      <mat-icon>login</mat-icon>
      <span>{{ 'i18n.homePage.homeCta.loginOrRegisterButtonLabel' | translate }}</span>
    </button>
  </div>

  <div fxFlex></div>
</div>
