export enum Adb2cClaims {
  ROLE = 'extension_Role'
}

export enum DeviceApiEndpoints {
  DEVICES = 'devices',
  /** api/devices/{deviceId}/config */
  SUB_DEVICES_CONFIG = 'config',
  /** api/devices/{deviceId}/rename */
  SUB_DEVICES_RENAME = 'rename'
}

export enum RuleApiEndpoints {
  RULES = 'rules'
}

export const DEFAULT_PROFILE = 'P0';
export const DEFAULT_CUSTOM_PROFILE = 'C0';
