import { NgModule } from '@angular/core';
import { BalluffSplashScreenModule } from '@balluff/ngx-standard/splash-screen';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalDialogModule } from 'src/app/shared/ui/modal-dialog/modal-dialog.module';
import { InvitePage } from './invite.page';

@NgModule({
  declarations: [InvitePage],
  imports: [SharedModule, BalluffSplashScreenModule, ModalDialogModule]
})
export class InvitePageModule {}
