import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, map, takeUntil } from 'rxjs';
import { DestroyableComponent } from 'src/app/shared/ui/destroyable/destroyable.component';

@Component({
  selector: 'pms-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePage extends DestroyableComponent implements OnInit {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msal: MsalService,
    private msalBroadcast: MsalBroadcastService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.msalBroadcast.inProgress$
      .pipe(
        takeUntil(this.destroying$),
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        map(() => this.msal.instance.getAllAccounts().length > 0),
        filter((isLoggedIn: boolean) => isLoggedIn === true)
      )
      .subscribe(() => {
        this.router.navigateByUrl('/auth');
      });
  }

  onLoginButtonPressed(): void {
    if (this.msalGuardConfig.authRequest) {
      this.msal.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        redirectStartPage: '/auth'
      } as RedirectRequest);
    } else {
      this.msal.loginRedirect();
    }
  }
}
