import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pms-interaction-dialog',
  template: `<h1 mat-dialog-title [ngClass]="data.isError ? 'dialog__title--error' : ''">
      {{ data.title | translate }}
    </h1>
    <mat-dialog-content class="mat-typography">
      <h2
        [innerHTML]="
          data.subtitle | translate: { customSubtitleValue: this.data.customSubtitleValue }
        "
      ></h2>
      <p [innerHTML]="data.content | translate"></p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button color="primary" mat-dialog-close cdkFocusInitial>
        {{ data.ctaLabel | translate }}
      </button>
    </mat-dialog-actions> `,
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isError?: boolean;
      title: string;
      subtitle: string;
      customSubtitleValue?: string;
      content: string;
      ctaLabel: string;
    }
  ) {}
}
