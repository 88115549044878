<mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1em">
  <a href="https://www.youtube.com/user/BalluffSensors/featured" target="_blank">
    <mat-icon svgIcon="youtube"></mat-icon>
  </a>
  <a href="https://twitter.com/balluff?lang=de" target="_blank">
    <mat-icon svgIcon="twitter"></mat-icon>
  </a>
  <a href="https://de-de.facebook.com/balluff.gmbh/" target="_blank">
    <mat-icon svgIcon="facebook"></mat-icon>
  </a>
  <a href="https://www.linkedin.com/company/76933887" target="_blank">
    <mat-icon svgIcon="linkedin"></mat-icon>
  </a>
  <a href="https://www.xing.com/companies/balluffgmbh" target="_blank">
    <mat-icon svgIcon="xing"></mat-icon>
  </a>
</mat-toolbar>
