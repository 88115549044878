import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeCtaModule } from '../../ui/home-cta/home-cta.module';
import { HomeFooterModule } from '../../ui/home-footer/home-footer.module';
import { HomeHeaderModule } from '../../ui/home-header/home-header.module';
import { HomePage } from './home.page';

@NgModule({
  imports: [SharedModule, HomeHeaderModule, HomeFooterModule, HomeCtaModule, MatIconModule],
  declarations: [HomePage]
})
export class HomePageModule {}
