import { Component } from '@angular/core';
import { HelpConfig } from '@balluff/ngx-standard/help';
import { HELPCONFIG } from 'src/app/help/data-source/help.data';

@Component({
  selector: 'pms-help',
  templateUrl: './help.page.html',
  styleUrls: ['./help.page.scss']
})
export class HelpPage {
  helpConfig: HelpConfig = HELPCONFIG;
}
