import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'pms-home-cta',
  templateUrl: './home-cta.component.html',
  styleUrls: ['./home-cta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeCtaComponent {
  @Output() loginButtonPressed = new EventEmitter<void>();
}
