import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { ModalDialogComponent } from './modal-dialog.component';

@NgModule({
  declarations: [ModalDialogComponent],
  imports: [SharedModule, MatButtonModule, MatDialogModule, TranslateModule.forChild()],
  exports: [ModalDialogComponent]
})
export class ModalDialogModule {}
