/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppFacade } from '../../../app.facade';
import { LocalPersistenceService } from '../../data-access/local-persistence.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutOn403Interceptor implements HttpInterceptor {
  constructor(private appFacade: AppFacade, private persistence: LocalPersistenceService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => this.logoutOn403(error)));
  }

  private logoutOn403(error) {
    if (error.status === 403) {
      this.persistence.saveLogoutOn403(true);
      this.appFacade.logout();
    }
    return throwError(error);
  }
}
