import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { DemoLoginRedirectPage } from './demo-login-redirect/demo-login-redirect.page';
import { HelpPage } from './help/ui/help/help.page';
import { HomePage } from './home/feature/home/home.page';
import { InvitePage } from './invite/feature/invite.page';
import { CodeQueryParamExistsGuard } from './shared/feature/guards/code-query-param-exists.guard';
import { CustomerIdGuard } from './shared/feature/guards/customer-id.guard';

const routes: Routes = [
  {
    path: 'devices',
    canActivate: [MsalGuard, CustomerIdGuard],
    loadChildren: () =>
      import('./gateways-overview/feature/gateways-overview/gateways-overview.module').then(
        m => m.GatewaysOverviewPageModule
      )
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./register-customer/feature/register-customer/register-customer.module').then(
        m => m.RegisterCustomerPageModule
      )
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/ui/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'demo',
    component: DemoLoginRedirectPage
  },
  {
    path: 'home',
    component: HomePage
  },
  {
    path: 'help',
    component: HelpPage
  },
  {
    path: 'login',
    component: InvitePage,
    canActivate: [CodeQueryParamExistsGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth-redirect/auth-redirect.module').then(m => m.AuthRedirectModule)
  },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
