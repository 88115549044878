import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-common';
import { IAuthService, LoginHttpResponse, UserInfo, UserRole } from '@balluff/ngx-standard/auth';
import { BalluffHttpMessage } from '@balluff/ngx-standard/core';
import { BehaviorSubject, combineLatest, lastValueFrom } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { CustomerInfo } from 'src/app/shared/data-access/models/customer-info.model';
import { getNameForAccountInfo } from 'src/app/shared/utils/user-name.util';
import { Adb2cClaims } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class HeaderAuthService implements IAuthService {
  private accountInfo$ = this.appState.accountInfo$;
  private isLoggedIn$ = this.appState.isLoggedIn$;
  private customerInfo$ = this.appState.customerInfo$;
  private loadUserInfo$ = this.isLoggedIn$
    .pipe(
      filter(isLoggedIn => isLoggedIn),
      switchMap(() => combineLatest([this.accountInfo$, this.customerInfo$]))
    )
    .pipe(filter(([acc]) => !!acc));

  user = new BehaviorSubject<UserInfo>(undefined);
  pwResetRequiredForCurrentUser = false;

  token: string;

  constructor(private appState: AppState) {
    this.loadUserInfo$.subscribe(([acc, cus]) => this.setUserInfo(acc, cus));
  }

  private setUserInfo(accountInfo: AccountInfo, customerInfo: CustomerInfo) {
    const role =
      accountInfo?.idTokenClaims[Adb2cClaims.ROLE] === undefined
        ? []
        : accountInfo?.idTokenClaims[Adb2cClaims.ROLE] === 'Admin'
        ? [UserRole.Admin]
        : [UserRole.User];

    const user: UserInfo = {
      userId: accountInfo.localAccountId,
      username: getNameForAccountInfo(accountInfo),
      email: accountInfo.idTokenClaims['emails'][0],
      userInfo: customerInfo?.name,
      role
    };
    this.user.next(user);
  }

  // Used only for header.
  isAdmin(user: UserInfo): boolean {
    return user.role?.includes(UserRole.Admin);
  }

  async isLoggedIn(): Promise<boolean> {
    return lastValueFrom(this.isLoggedIn$);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getUserImage(userId: string): Promise<Blob> {
    return null;
  }

  // NOT IMPLEMENTED.
  initialize(): void {
    throw new Error('Method not implemented.');
  }
  async logout(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  invalidate(): void {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login(username: string, password: string): Promise<LoginHttpResponse> {
    throw new Error('Method not implemented.');
  }
  getUsers(): Promise<UserInfo[]> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addUser(user: UserInfo): Promise<UserInfo> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUser(userId: string): Promise<UserInfo> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateUser(user: UserInfo): Promise<BalluffHttpMessage> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteUser(userId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getCurrentUser(): Promise<UserInfo> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUserImage(userId: string, image: Blob): Promise<void> {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteUserImage(userId: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getCapabilities(): Promise<string[]> {
    throw new Error('Method not implemented.');
  }
}
