import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus, ServerError } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, take } from 'rxjs/operators';
import { AppState } from './app.state';
import { LocalPersistenceService } from './shared/data-access/local-persistence.service';
import { SignalRService } from './shared/data-access/signalr.service';
import { UiService } from './shared/utils/ui/ui.service';

@Injectable({
  providedIn: 'root'
})
export class AppFacade {
  readonly accountInfo$ = this.appState.accountInfo$;
  readonly customerInfo$ = this.appState.customerInfo$;
  readonly isAdmin$ = this.appState.isAdmin$;

  constructor(
    private appState: AppState,
    private domSanitizer: DomSanitizer,
    private persistence: LocalPersistenceService,
    private matIconRegistry: MatIconRegistry,
    private msal: MsalService,
    private msalBroadcast: MsalBroadcastService,
    private signalR: SignalRService,
    private translate: TranslateService,
    private ui: UiService
  ) {
    this.msal
      .handleRedirectObservable()
      .pipe()
      .subscribe({
        error: (err: ServerError) => {
          if (err.errorMessage.indexOf('AADB2C90091') > -1) {
            location.reload();
          }
        }
      });

    this.msalBroadcast.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        map(() => this.msal.instance.getActiveAccount())
      )
      .subscribe(accountInfo => {
        this.appState.setAccountInfo(accountInfo);
        this.appState.setIsLoggedIn(this.msal.instance.getAllAccounts().length > 0);
      });
  }

  initializeIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/youtube.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/twitter.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'xing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/xing.svg')
    );
  }

  initializeTranslations(): void {
    this.translate.setDefaultLang('de');

    const balluffBrowserLang = this.persistence.loadBalluffBrowserLang();
    const browserLang = this.translate.getBrowserLang();
    const defaultLang = this.translate.getDefaultLang();

    this.translate.use(
      this.translate.currentLang ?? balluffBrowserLang ?? browserLang ?? defaultLang
    );
  }

  handleChangingUserPermissions(): void {
    if (this.persistence.loadLogoutOn403() === true) {
      this.persistence.saveLogoutOn403(false);

      const message = this.translate.instant('i18n.app.permissionsChangedLabel');

      this.ui.showInfoBar(message);
    }
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.msal.instance.getActiveAccount();

    if (!activeAccount && this.msal.instance.getAllAccounts().length > 0) {
      const accounts = this.msal.instance.getAllAccounts();
      this.msal.instance.setActiveAccount(accounts[0]);
      this.appState.setAccountInfo(accounts[0]);
    }
  }

  logout(): void {
    this.appState.clearAccountInfo();
    this.appState.clearCustomerInfo();
    this.signalR.disconnectFromHub();
    this.msal.logoutRedirect();
  }

  connectToSignalRHub(): void {
    this.appState.accountInfo$
      .pipe(filter(accountInfo => !!accountInfo))
      .subscribe(accountInfo => this.signalR.connectToHub(accountInfo.localAccountId));
  }

  disconnectFromSignalRHub(): void {
    this.signalR.disconnectFromHub();
  }

  updateCustomerName(newName: string): void {
    this.appState.customerInfo$
      .pipe(take(1))
      .subscribe(customerInfo => this.appState.setCustomerInfo({ ...customerInfo, name: newName }));
  }
}
