import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeCtaComponent } from './home-cta.component';

@NgModule({
  imports: [SharedModule, MatIconModule, MatButtonModule],
  exports: [HomeCtaComponent],
  declarations: [HomeCtaComponent]
})
export class HomeCtaModule {}
