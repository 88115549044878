import { Component } from '@angular/core';
import { HeaderFacade } from 'src/app/header/data-access/header.facade';

@Component({
  selector: 'pms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  readonly isMobile$ = this.headerFacade.isMobile$;
  readonly isAdmin$ = this.headerFacade.isAdmin$;
  readonly isLoggedIn$ = this.headerFacade.isLoggedIn$;

  constructor(private headerFacade: HeaderFacade) {}

  onLogout(): void {
    this.headerFacade.logout();
  }

  onChangePassword(): void {
    this.headerFacade.resetPassword();
  }

  onEditProfile(): void {
    this.headerFacade.editProfile();
  }
}
