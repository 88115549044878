import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { AppState } from 'src/app/app.state';

@Injectable()
export class InviteService {
  // Data | Outputs
  readonly isLoggedIn$ = this.appState.isLoggedIn$;

  constructor(
    private appState: AppState,
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private msal: MsalService,
    private router: Router
  ) {}

  // Actions
  navigateToWelcome(): void {
    this.router.navigateByUrl('/');
  }

  navigateToDevices(): void {
    this.router.navigateByUrl('/devices');
  }

  logout(): void {
    this.msal.logoutRedirect();
  }

  loginWithCode(code: string): void {
    this.msal.loginRedirect({
      ...this.msalGuardConfig.authRequest,
      state: 'auth' + code
    } as RedirectRequest);
  }
}
