import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerInfo } from 'src/app/shared/data-access/models/customer-info.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'any'
})
export class CustomerApiService {
  private API_ENDPOINT = environment.api.uri;

  constructor(private http: HttpClient) {}

  createCustomerAccount(name: string): Observable<void> {
    const url = `${this.API_ENDPOINT}/RegisterCustomer`;
    return this.http.post<void>(url, { name });
  }

  verifyCustomerName(name: string): Observable<string> {
    const url = `${this.API_ENDPOINT}/VerifyCustomerName`;
    return this.http.get<string>(url, { params: { name } });
  }

  /**
   * @param code Invitation code from Link in Email
   * @returns Customer ID of the inviter
   */
  acceptInvitation(invitationCode: string): Observable<string> {
    const url = `${this.API_ENDPOINT}/validate`;
    return this.http.post(url, invitationCode, { responseType: 'text' });
  }

  getCustomerInfo(): Observable<CustomerInfo> {
    const url = `${this.API_ENDPOINT}/customerinfo`;
    return this.http.get<CustomerInfo>(url);
  }

  deleteCustomer(customerId: string): Observable<never> {
    const url = `${this.API_ENDPOINT}/customers/${customerId}`;
    return this.http.delete<never>(url);
  }

  renameCustomer(newName: string): Observable<never> {
    const url = `${this.API_ENDPOINT}/customers/me`;
    return this.http.patch<never>(url, { newName });
  }
}
