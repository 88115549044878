import { AccountInfo } from "@azure/msal-browser";

export const getNameForAccountInfo = (accountInfo: AccountInfo): string => {
  let name = '';
  if (accountInfo.name && accountInfo.name !== 'unknown') {
    name = accountInfo.name;
  } else {
    const givenName = accountInfo.idTokenClaims['given_name']
      ? accountInfo.idTokenClaims['given_name']
      : '';
    const familyName = accountInfo.idTokenClaims['family_name']
      ? accountInfo.idTokenClaims['family_name']
      : '';
    name = `${givenName} ${familyName}`;
  }
  return name;
};
