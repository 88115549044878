import { HelpConfig, LinkTarget } from '@balluff/ngx-standard/help';

export const HELPCONFIG: HelpConfig = {
  about: {
    appName: 'Portable Monitoring System',
    appVersion: '1.3.3',
    yearFirstRelease: 2021,
    licenses: [],
    licenseLinks: [
      {
        href: 'https://www.balluff.de',
        linktext: 'Balluff Website',
        target: LinkTarget._blank
      }
    ]
  },
  contact: {
    /* See linking in angular.json -> architect.build.options.assets */
    iconPath: 'assets/flags/'
  }
};
