import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
  BrowserAuthOptions,
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const browserAuthOptions: BrowserAuthOptions = {
  clientId: environment.adb2c.clientId,
  authority: environment.adb2c.authorities.signUpSignIn.authority,
  redirectUri: '/',
  postLogoutRedirectUri: '/',
  knownAuthorities: [environment.adb2c.authorityDomain],
  navigateToLoginRequestUrl: true
};

const loggerCallback = (logLevel: LogLevel, message: string) => {
  if (!environment.production) {
    switch (logLevel) {
      case LogLevel.Error: {
        console.error(message);
        break;
      }
      case LogLevel.Warning: {
        console.warn(message);
        break;
      }
      default: {
        console.log(message);
        break;
      }
    }
  }
};

const msalInstanceFactory = () =>
  new PublicClientApplication({
    auth: browserAuthOptions,
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: environment.production ? LogLevel.Error : LogLevel.Warning,
        piiLoggingEnabled: false
      }
    }
  });

const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set(environment.api.uri, environment.adb2c.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
};

const msalGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: [...environment.adb2c.scopes]
  },
  loginFailedRoute: 'welcome'
});

@NgModule({
  imports: [CommonModule, MsalModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ]
})
export class MsalConfigModule {}
