import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { zip } from 'rxjs';
import { concatMap, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppFacade } from 'src/app/app.facade';
import { CustomerApiService } from 'src/app/register-customer/data-access/customer-api/customer-api.service';
import { CustomerInfo } from 'src/app/shared/data-access/models/customer-info.model';
import { DestroyableComponent } from 'src/app/shared/ui/destroyable/destroyable.component';
import { ModalDialogComponent } from 'src/app/shared/ui/modal-dialog/modal-dialog.component';
import { DialogData, UiService } from 'src/app/shared/utils/ui/ui.service';
import { InviteService } from '../data-access/invite.service';

@Component({
  selector: 'pms-login',
  template: `<balluff-splash-screen
    logoUrl="assets/images/logos/BalluffLogoColor.svg"
    title="{{ 'i18n.common.isLoading' | translate }}"
  >
  </balluff-splash-screen> `,
  providers: [InviteService]
})
export class InvitePage extends DestroyableComponent implements OnInit {
  private isLoggedIn$ = this.loginFacade.isLoggedIn$;
  private code$ = this.activatedRoute.queryParams.pipe(map(params => params.code));

  constructor(
    private activatedRoute: ActivatedRoute,
    private appFacade: AppFacade,
    private customerApi: CustomerApiService,
    private loginFacade: InviteService,
    private msalBroadcast: MsalBroadcastService,
    private ui: UiService
  ) {
    super();
  }

  /**
   * isLoggedIn === false
   * hasCode === true
   * ✅  ==> loginRedirect with code
   */
  private notLoggedInAndHasCode$ = zip(this.isLoggedIn$, this.code$).pipe(
    takeUntil(this.destroying$),
    filter(([isLoggedIn, code]) => !isLoggedIn && !!code)
  );

  /**
   * isLoggedIn === true
   * hasCode === true
   * ✅  ==> show success dialog and logout user on dialog dismissal
   * ❌  ==> show error dialog and redirect to /customer/register'
   * on dialog dismissal
   */
  private loggedInAndHasCode$ = zip(this.isLoggedIn$, this.code$).pipe(
    takeUntil(this.destroying$),
    filter(([isLoggedIn, code]) => isLoggedIn && !!code),
    map(([, code]) => code),
    switchMap(code => {
      return this.customerApi.acceptInvitation(code);
    }),
    concatMap(() => {
      return this.customerApi.getCustomerInfo();
    })
  );

  ngOnInit(): void {
    this.msalBroadcast.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$),
        tap(() => {
          this.appFacade.checkAndSetActiveAccount();
        })
      )
      .subscribe(() => {
        this.notLoggedInAndHasCode$.subscribe(([, code]) => {
          this.loginFacade.loginWithCode(code);
        });

        this.loggedInAndHasCode$.subscribe({
          next: customerInfo =>
            this.showSuccessDialog(customerInfo)
              .afterClosed()
              .pipe(takeUntil(this.destroying$))
              .subscribe(() => {
                this.loginFacade.navigateToDevices();
              }),
          error: err => {
            console.error(err);
            this.showErrorDialog(err)
              .afterClosed()
              .pipe(takeUntil(this.destroying$))
              .subscribe(() => {
                this.loginFacade.logout();
              });
          }
        });
      });
  }

  private showSuccessDialog(
    customer: CustomerInfo
  ): MatDialogRef<ModalDialogComponent, DialogData> {
    return this.ui.showInfoDialog({
      title: 'i18n.inviteSuccesDialog.titleLabel',
      subtitle: 'i18n.inviteSuccesDialog.subtitleLabel',
      customSubtitleValue: customer.name,
      content: 'i18n.inviteSuccesDialog.contentLabel',
      ctaLabel: 'i18n.inviteSuccesDialog.ctaLabel'
    });
  }

  private showErrorDialog(err: HttpErrorResponse): MatDialogRef<ModalDialogComponent, DialogData> {
    return this.ui.showErrorDialog({
      subtitle: `i18n.inviteFailureDialog.subtitleLabel`,
      customSubtitleValue: err.error,
      content: 'i18n.inviteFailureDialog.contentLabel'
    });
  }
}
