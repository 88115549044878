import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Language, SUPPORTED_LANGUAGES_TOKEN } from '@balluff/ngx-standard/translation';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs';
import { LocalPersistenceService } from 'src/app/shared/data-access/local-persistence.service';

@Component({
  selector: 'pms-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeHeaderComponent {
  currentLanguage$ = this.translate.onLangChange.pipe(
    map((event: LangChangeEvent) => event.lang),
    startWith(this.translate.currentLang)
  );

  constructor(
    @Inject(SUPPORTED_LANGUAGES_TOKEN) public languages: Language[],
    private translate: TranslateService,
    private persistence: LocalPersistenceService
  ) {}

  changeLanguage(code: string): void {
    this.translate.use(code);
    this.persistence.saveBalluffBrowserLang(code);
  }

  getLanguage(code: string): Language {
    return this.languages.find(language => language.code === code);
  }
}
