import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AuthService } from '@balluff/ngx-standard/auth';
import {
  BalluffTranslationModule,
  SUPPORTED_LANGUAGES_TOKEN
} from '@balluff/ngx-standard/translation';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DemoLoginRedirectPageModule } from './demo-login-redirect/demo-login-redirect.module';
import { HeaderAuthService } from './header/data-access/header-auth.service';
import { HeaderModule } from './header/feature/header.module';
import { HelpPageModule } from './help/ui/help/help.module';
import { HomePageModule } from './home/feature/home/home.module';
import { InvitePageModule } from './invite/feature/invite.module';
import { MsalConfigModule } from './msal-config.module';
import { LogoutOn403Interceptor } from './shared/feature/interceptors/logout-on-403.interceptor';

registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeEs);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BalluffTranslationModule.forRoot({
      defaultLanguage: 'de'
    }),
    BrowserAnimationsModule,
    BrowserModule,
    DemoLoginRedirectPageModule,
    HomePageModule,
    HeaderModule,
    HelpPageModule,
    HttpClientModule,
    InvitePageModule,
    MsalConfigModule
  ],
  providers: [
    {
      provide: AuthService,
      useExisting: HeaderAuthService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutOn403Interceptor,
      multi: true
    },
    {
      provide: SUPPORTED_LANGUAGES_TOKEN,
      useValue: [
        { code: 'de', name: 'Deutsch', flag: 'de' },
        { code: 'en', name: 'English', flag: 'uk' },
        { code: 'es', name: 'Español', flag: 'es' }
      ]
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
